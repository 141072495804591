var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "inner" },
    [
      _c("header", { staticClass: "app-welcome-message" }, [
        _c("h1", [_vm._v("Master data")]),
      ]),
      _c(
        "v-container",
        { attrs: { fluid: "", "pa-0": "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _vm._v(" Overzicht van alle master data tabellen "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }